import fetch from "isomorphic-fetch"
import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client"
import { STRAPI_API_REQ } from "../consts/api"

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: new HttpLink({
    uri: STRAPI_API_REQ,
    fetch,
  }),
})

export default client
