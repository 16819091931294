exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-documents-documents-js": () => import("./../../../src/pages/about/documents/Documents.js" /* webpackChunkName: "component---src-pages-about-documents-documents-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-certificate-js": () => import("./../../../src/pages/certificate.js" /* webpackChunkName: "component---src-pages-certificate-js" */),
  "component---src-pages-combo-js": () => import("./../../../src/pages/combo.js" /* webpackChunkName: "component---src-pages-combo-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-family-js": () => import("./../../../src/pages/family.js" /* webpackChunkName: "component---src-pages-family-js" */),
  "component---src-pages-home-index-js": () => import("./../../../src/pages/home/index.js" /* webpackChunkName: "component---src-pages-home-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-photo-js": () => import("./../../../src/pages/photo.js" /* webpackChunkName: "component---src-pages-photo-js" */),
  "component---src-pages-promo-js": () => import("./../../../src/pages/promo.js" /* webpackChunkName: "component---src-pages-promo-js" */),
  "component---src-pages-review-js": () => import("./../../../src/pages/review.js" /* webpackChunkName: "component---src-pages-review-js" */),
  "component---src-pages-teachers-js": () => import("./../../../src/pages/teachers.js" /* webpackChunkName: "component---src-pages-teachers-js" */),
  "component---src-pages-training-js": () => import("./../../../src/pages/training.js" /* webpackChunkName: "component---src-pages-training-js" */),
  "component---src-templates-course-js": () => import("./../../../src/templates/course.js" /* webpackChunkName: "component---src-templates-course-js" */),
  "component---src-templates-news-detail-js": () => import("./../../../src/templates/newsDetail.js" /* webpackChunkName: "component---src-templates-news-detail-js" */),
  "component---src-templates-photo-detail-photo-detail-by-id-js": () => import("./../../../src/templates/photoDetail/photoDetailById.js" /* webpackChunkName: "component---src-templates-photo-detail-photo-detail-by-id-js" */),
  "component---src-templates-photo-detail-photo-detail-school-js": () => import("./../../../src/templates/photoDetail/photoDetailSchool.js" /* webpackChunkName: "component---src-templates-photo-detail-photo-detail-school-js" */),
  "component---src-templates-photo-detail-photo-detail-team-js": () => import("./../../../src/templates/photoDetail/photoDetailTeam.js" /* webpackChunkName: "component---src-templates-photo-detail-photo-detail-team-js" */),
  "component---src-templates-teachers-detail-teachers-detail-js": () => import("./../../../src/templates/teachersDetail/teachersDetail.js" /* webpackChunkName: "component---src-templates-teachers-detail-teachers-detail-js" */)
}

